body {
  background-color: rgb(250,250,250);
}

.datePickerDiv {
  text-align: center;
  border-style: solid;
}

.nasaDiv {
  background-color: white;
  margin: 5% 10% 3% 10%;
  border-radius: 1%;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(225, 225, 225);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding:  0px 30px 0px 30px;
}

.nasaExplanation {
  color: #506ed1;
}

.nasaImage {
  /* border: 1px solid #fff000; */
  /* border: 5px solid darkcyan; */
  border-radius: 2%;
  /* width: 500px;
  height: 500px; */
  width: 100%;
  /* fill: darkcyan; */
  text-align: center;
  /* justify-content: center;
   */
}

.iframeDiv{
  border-radius: 2%;
  text-align: center;
}
.credit {
  color: #73b4f1;
  font-style: italic;
}

.title {
  color: #3a3c6e;
}

.date {
  color: #506ed1;
}

.icon {
  font-size: 2rem;
}